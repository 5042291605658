<template>
  <div>
    <div class="user-procotol">
      <div class="user-procotol-box">
        <van-sticky>
          <div class="user-procotol-title">
            <div class="user-procotol-back">
              <span @click="back">
                <van-icon name="arrow-left" size="18px" />
              </span>
            </div>
            <div class="user-procotol-t">
              隐私政策
            </div>
          </div>
        </van-sticky>
        <div class="user-oricotol-content">
          <div v-html="this.policy" class="user-text"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Toast } from 'vant';
import { _isMobile } from '@/util/equipment';
import { policy  } from '@/util/procotol';

export default {
  data() {
    return {
        policy ,
    };
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
  },
  mounted() {},
};
</script>
<style lang="less">
.user-procotol {
  height: 100vh;
  background-image: url('../../../assets/image/blogin-bg.jpg');
  background-position: 50% 50%;
  background-size: cover;
  .user-procotol-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 345px;
    height: 530px;
    background: #fff;
    box-shadow: 0px 2px 16px 0px rgba(12, 28, 65, 0.2);
    border-radius: 11px;
    border: 2px solid;

    border-image: linear-gradient(
        224deg,
        rgba(255, 255, 255, 0.5),
        rgba(255, 255, 255, 0.33),
        rgba(255, 255, 255, 0.08)
      )
      2 2;
    .user-procotol-title {
      padding: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .user-procotol-back {
      }
      .user-procotol-t {
        font-size: 18px;
        margin-right: 120px;
        font-weight: 700;
      }
    }
    .user-oricotol-content {
      overflow-y: auto;
      height: 450px;
      .user-text {
        height: 100%;
        font-size: 12px;
        padding: 5px;
      }
    }
  }
}
</style>
